.card {
  background-color: var(--surface-card);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: $borderRadius;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08) !important;

  &.card-w-title {
    padding-bottom: 2rem;
  }
}

.no-icon {
  .p-confirm-dialog-message {
    margin-left: 0 !important;
  }
}

.form-card {
  background-color: var(--surface-ground);
  padding: 1rem;
}

.form-card-border {
  background-color: var(--surface-0);
  border: 1px solid #ced4da;
  padding: 0.5rem;
  overflow: auto;

  &.assessment-box {
    font-weight: 600;
    text-align: center;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.score-wrapper {
  width: 20%;
  padding: 0 0.5rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.score-options {
  display: flex;

  .p-button {
    margin: 0 0.5rem;
    width: calc(20% - 1rem);
    align-items: center;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0;
    border: 1px solid #ced4da !important;

    &:first-child {
      margin-left: 0;
      width: calc(20% - 0.5rem);
    }

    &:last-child {
      margin-right: 0;
      width: calc(20% - 0.5rem);
    }

    &.p-highlight {
      background-color: #b4c7e7;
      color: var(--text-color);

      .p-button-label {
        font-weight: 600;
      }
    }

    .p-button-label {
      font-weight: normal;
    }
  }
}

.tabview-custom {
  .p-tabview-nav-btn {
    box-shadow: none !important;
  }

  .p-tabview-panels {
    padding: 0;
  }
}

.primary-accordion {
  .p-accordion-header-link {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--primary-color-text) !important;
  }
}
