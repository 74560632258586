.p-datatable-table {
  word-break: break-word;

  .p-datatable-frozen-tbody {
    font-weight: bold;
  }

  .p-datatable-scrollable {
    .p-frozen-column {
      font-weight: bold;
    }
  }

  .image-text {
    vertical-align: middle;
    margin-left: 0.5rem;
  }
}

.p-datatable-emptymessage {
  justify-content: center;
}

.table-tooltip {
  .p-tooltip-text {
    font-size: 12px;
    padding: 0.5rem;
  }
}
